/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

(function($) {
  $.fn.autoKana = function(element1, element2, passedOptions) {
    var options = $.extend(
      {
        katakana: false
      },
      passedOptions
    );

    var kana_extraction_pattern = new RegExp("[^ 　ぁあ-んー]", "g");
    var kana_compacting_pattern = new RegExp("[ぁぃぅぇぉっゃゅょ]", "g");
    var elName,
      elKana,
      active = false,
      timer = null,
      flagConvert = true,
      input,
      values,
      ignoreString,
      baseKana;

    elName = $(element1);
    elKana = $(element2);
    active = true;
    _stateClear();

    elName.blur(_eventBlur);
    elName.focus(_eventFocus);
    elName.keydown(_eventKeyDown);

    function start() {
      active = true;
    }

    function stop() {
      active = false;
    }

    function toggle(event) {
      var ev = event || window.event;
      if (event) {
        var el = Event.element(event);
        if (el.checked) {
          active = true;
        } else {
          active = false;
        }
      } else {
        active = !active;
      }
    }

    function _checkConvert(new_values) {
      if (!flagConvert) {
        if (Math.abs(values.length - new_values.length) > 1) {
          var tmp_values = new_values
            .join("")
            .replace(kana_compacting_pattern, "")
            .split("");
          if (Math.abs(values.length - tmp_values.length) > 1) {
            _stateConvert();
          }
        } else {
          if (values.length == input.length && values.join("") != input) {
            if (input.match(kana_extraction_pattern)) {
              _stateConvert();
            }
          }
        }
      }
    }

    function _checkValue() {
      var new_input, new_values;
      new_input = elName.val();
      if (new_input == "") {
        _stateClear();
        _setKana();
      } else {
        new_input = _removeString(new_input);
        if (input == new_input) {
          return;
        } else {
          input = new_input;
          if (!flagConvert) {
            new_values = new_input
              .replace(kana_extraction_pattern, "")
              .split("");
            _checkConvert(new_values);
            _setKana(new_values);
          }
        }
      }
    }

    function _clearInterval() {
      clearInterval(timer);
    }

    function _eventBlur(event) {
      _clearInterval();
    }
    function _eventFocus(event) {
      _stateInput();
      _setInterval();
    }
    function _eventKeyDown(event) {
      if (flagConvert) {
        _stateInput();
      }
    }
    function _isHiragana(chara) {
      return (
        (chara >= 12353 && chara <= 12435) || chara == 12445 || chara == 12446
      );
    }
    function _removeString(new_input) {
      if (new_input.indexOf(ignoreString) !== -1) {
        return new_input.replace(ignoreString, "");
      } else {
        var i, ignoreArray, inputArray;
        ignoreArray = ignoreString.split("");
        inputArray = new_input.split("");
        for (i = 0; i < ignoreArray.length; i++) {
          if (ignoreArray[i] == inputArray[i]) {
            inputArray[i] = "";
          }
        }
        return inputArray.join("");
      }
    }
    function _setInterval() {
      var self = this;
      timer = setInterval(_checkValue, 30);
    }
    function _setKana(new_values) {
      if (!flagConvert) {
        if (new_values) {
          values = new_values;
        }
        if (active) {
          var _val = _toKatakana(baseKana + values.join(""));
          elKana.val(_val);
        }
      }
    }
    function _stateClear() {
      baseKana = "";
      flagConvert = false;
      ignoreString = "";
      input = "";
      values = [];
    }
    function _stateInput() {
      baseKana = elKana.val();
      flagConvert = false;
      ignoreString = elName.val();
    }
    function _stateConvert() {
      baseKana = baseKana + values.join("");
      flagConvert = true;
      values = [];
    }
    function _toKatakana(src) {
      if (options.katakana) {
        var c, i, str;
        str = new String();
        for (i = 0; i < src.length; i++) {
          c = src.charCodeAt(i);
          if (_isHiragana(c)) {
            str += String.fromCharCode(c + 96);
          } else {
            str += src.charAt(i);
          }
        }
        return str;
      } else {
        return src;
      }
    }
  };
})(jQuery);
