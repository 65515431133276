<template>
  <div id="input" :style="{ backgroundColor: sexStyleType.color02 }">
    <headerEl />
    <main id="wrap" class="has-width-limit">
      <div id="user-information-vue">
        <!-- 头部导航 -->
        <div class="step-header block" :style="{ backgroundColor: sexStyleType.color02 }">
          <div class="block-head" :style="{ color: sexStyleType.color01 }">
            <h2>ご予約はカンタン3ステップ！</h2>
          </div>
          <headerArrow :type="1" />
        </div>
        <!-- 院座信息 -->
        <div class="reserve-card block" :style="{ backgroundColor: sexStyleType.color01 }">
          <div class="block-head cols">
            <div class="col">
              <p>ご予約のクリニック</p>
              <h2>{{ completeInfo.shopName }}</h2>
            </div>
            <a href="javascript:void(0);" id="startBtn" class="small-button" @click="change"
              :style="{ color: sexStyleType.color01, backgroundColor: sexStyleType.color02 }">
              変更する
            </a>
          </div>
          <div class="block-body">
            <div class="cols">
              <div class="col">
                <p>
                  <small>ご予約日 :</small>
                  <br class="mq" />
                  {{ completeInfo.reserveDate | filterTime }}
                </p>
              </div>
              <div class="col">
                <p>
                  <small>ご予約時間 :</small>
                  <br class="mq" />
                  {{ completeInfo.reserveTime }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- 个人信息录入 -->
        <section class="input-form-section">
          <p class="section-head" :style="{ color: sexStyleType.color01 }">
            ご予約のクリニック・日時にお間違えがない事を確認のうえ、下記に患者さま情報をご入力ください
          </p>

          <div v-show="errShow" class="error reserveErrorMessages"
            :style="{ borderColor: sexStyleType.color01, color: sexStyleType.color01, backgroundColor: sexStyleType.color02 }">
            <ul>
              <li v-show="err[0]" style="list-style: none">
                お名前 は必須項目です。
              </li>
              <li v-show="err[1]" style="list-style: none">
                ふりがな は必須項目です。
              </li>
              <li v-show="err[2]" style="list-style: none">
                生年月日(年) は必須項目です。
              </li>
              <li v-show="err[3]" style="list-style: none">
                生年月日(月) は必須項目です。
              </li>
              <li v-show="err[4]" style="list-style: none">
                生年月日(日) は必須項目です。
              </li>
              <li v-show="err[5]" style="list-style: none">
                ご連絡先電話番号 は必須項目です。
              </li>
              <li v-show="err[6]" style="list-style: none">
                メールアドレス は必須項目です。
              </li>
              <li v-show="err[7]" style="list-style: none">
                ふりがなはひらがなで入力してください。
              </li>
              <li v-show="err[8]" style="list-style: none">
                メールアドレスが正しい形式ではありません。
              </li>
              <li v-show="err[9]" style="list-style: none">
                ご連絡先電話番号は10桁から11桁以内で入力してください。
              </li>
            </ul>
          </div>

          <div class="section-body">
            <ul class="form-group">
              <li class="form-list" :style="{ borderBottomColor: sexStyleType.color01 }">
                <div class="text-input">
                  <p :style="{ color: sexStyleType.color01 }">お名前</p>
                  <label for="counselingReserve.name1">
                    <input name="counselingReserve.name1" :placeholder="sex == 1 ? '山田 太郎' : '山田 花子'" type="text" value=""
                      id="user_name"
                      :style="{ borderColor: sexStyleType.color01, backgroundColor: sexStyleType.color02 }" />
                  </label>
                  <span class="require" :style="{ backgroundColor: sexStyleType.color01 }">必須</span>
                </div>
              </li>
              <li class="form-list" :style="{ borderBottomColor: sexStyleType.color01 }">
                <div class="text-input">
                  <p :style="{ color: sexStyleType.color01 }">ふりがな</p>
                  <label for="kana">
                    <input name="counselingReserve.kana1" :placeholder="sex == 1 ? 'やまだ たろう' : 'やまだ はなこ'" type="text" value="" id="user_name_kana"
                      :style="{ borderColor: sexStyleType.color01, backgroundColor: sexStyleType.color02 }" />
                  </label>
                  <span class="require" :style="{ backgroundColor: sexStyleType.color01 }">必須</span>
                </div>
              </li>
              <li class="form-list" :style="{ borderBottomColor: sexStyleType.color01 }">
                <div class="text-input" style="height: 81px">
                  <p :style="{ color: sexStyleType.color01 }">生年月日</p>
                  <label class="select-box year mr-3"
                    :style="{ borderColor: sexStyleType.color01, backgroundColor: sexStyleType.color02 }">
                    <select v-model="birthday_y" name="counselingReserve.birthday_y">
                      <option value="">--</option>
                      <option v-for="item in birthdayYear" :key="item" :value="1950 + item">
                        {{ 1950 + item + "年" }}
                      </option>
                    </select>
                  </label>
                  <label class="select-box month mr-3"
                    :style="{ borderColor: sexStyleType.color01, backgroundColor: sexStyleType.color02 }">
                    <select v-model="birthday_m" name="counselingReserve.birthday_m">
                      <option selected>--</option>
                      <option v-for="item in 12" :key="item + 'YUE'" :value="item">
                        {{ item + "月" }}
                      </option>
                    </select>
                  </label>
                  <label class="select-box day mr-3"
                    :style="{ borderColor: sexStyleType.color01, backgroundColor: sexStyleType.color02 }">
                    <select v-model="birthday_d" name="counselingReserve.birthday_d">
                      <option selected>--</option>

                      <option v-for="item in 31" :key="item + 'ri'" :value="item">
                        {{ item + "日" }}
                      </option>
                    </select>
                  </label>
                  <span class="require" :style="{ backgroundColor: sexStyleType.color01 }">必須</span>
                </div>
              </li>
              <li class="form-list" :style="{ borderBottomColor: sexStyleType.color01 }">
                <div class="text-input">
                  <p :style="{ color: sexStyleType.color01 }">
                    ご連絡先電話番号
                    <small>(半角数字)</small>
                  </p>
                  <label for="tel">
                    <input name="counselingReserve.tel" placeholder="09012345678" v-model.trim="tel" @keyup="inputChange"
                      min="0" type="tel" maxlength="11" value=""
                      :style="{ borderColor: sexStyleType.color01, backgroundColor: sexStyleType.color02 }" />
                  </label>
                  <span class="require" :style="{ backgroundColor: sexStyleType.color01 }">必須</span>
                </div>
              </li>
              <li class="form-list mail" :style="{ borderBottomColor: sexStyleType.color01 }">
                <div class="text-input">
                  <p :style="{ color: sexStyleType.color01 }">メールアドレス</p>
                  <label for="email">
                    <input name="counselingReserve.mail" placeholder="example@jibun-clinic.com" type="email"
                      v-model.trim="mail" value=""
                      :style="{ borderColor: sexStyleType.color01, backgroundColor: sexStyleType.color02 }" />
                  </label>
                  <span class="require" :style="{ backgroundColor: sexStyleType.color01 }">必須</span>
                </div>
                <p style="font-size: 14px">
                  <i class="icon caution" :style="{ backgroundColor: sexStyleType.color01, color: '#fff' }">!</i>
                  <strong>ドメイン受信許可のお願い</strong>
                </p>
                <p>
                  迷惑メール対策設定が有効になっていると予約完了メールが届かない可能性がございます。<br />
                  <span :style="{ color: sexStyleType.color01 }">@reserve.jibun-clinic.com</span>の受信許可設定をお願いいたします。
                </p>
              </li>
            </ul>
          </div>
          <div class="section-foot">
            <button @click="confirm" class="large-form-button red"
              :style="{ backgroundColor: sexStyleType.color01, borderColor: sexStyleType.color01 }">
              入力内容を確認する
            </button>
          </div>
        </section>
      </div>
    </main>
    <footerEl />
  </div>
</template>

<script>
import headerEl from "@/components/header.vue";
import footerEl from "@/components/footer.vue";
import headerArrow from "@/components/headerArrow.vue";
import { mapState } from "vuex";
import "@/utils/jquery.autoKana.js";
import $ from "jquery";
$(document).ready(function () {
  $.fn.autoKana("#user_name", "#user_name_kana", {
    katakana: false, //true：カタカナ、false：ひらがな（デフォルト）
  });
});
export default {
  data() {
    return {
      err: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      errShow: false,
      name1: "",
      kana1: "",
      birthdayYear: new Date().getFullYear() - 1962,
      birthday_y: 1998,
      birthday_m: "--",
      birthday_d: "--",
      year_range: 48,
      tel: "",
      telReg: /^(\d{10}|\d{11})$/,
      mailReg: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
      dateReg: /^((?!0000)[0-9]{4}-((0[1-9]|1[0-2])-(0[1-9]|1[0-9]|2[0-8])|(0[13-9]|1[0-2])-(29|30)|(0[13578]|1[02])-31)|([0-9]{2}(0[48]|[2468][048]|[13579][26])|(0[48]|[2468][048]|[13579][26])00)-02-29)$/,
      kanaReg: /[ぁ-ん]+$/,
      mail: "",
    };
  },
  components: { headerEl, footerEl, headerArrow },
  computed: {
    ...mapState(["completeInfo", "userInfo", "sex", "sexStyleType"]),
  },
  mounted() {
    var date = new Date();
    let year = date.getFullYear(); //获取完整的年份(4位)
    this.birthday_y = year - 23;
    if (this.userInfo && this.userInfo.name1) {
      this.name1 = this.userInfo.name1;
      this.kana1 = this.userInfo.kana1;
      $("#user_name")[0].value = this.name1;
      $("#user_name_kana")[0].value = this.kana1;
      this.birthday_y = this.userInfo.birthday_y;
      this.birthday_m = this.userInfo.birthday_m;
      this.birthday_d = this.userInfo.birthday_d;
      this.tel = this.userInfo.tel;
      this.mail = this.userInfo.mail;
      window.scroll(0, 0);
    }
    if (sessionStorage.getItem('inputuserinfo')) {
      let inputuserInfo = JSON.parse(sessionStorage.getItem('inputuserinfo'))
      $("#user_name")[0].value = inputuserInfo.name1
      $("#user_name_kana")[0].value = inputuserInfo.kana1
      this.birthday_y = inputuserInfo.birthday_y
      this.birthday_m = inputuserInfo.birthday_m
      this.birthday_d = inputuserInfo.birthday_d
      this.tel = inputuserInfo.tel
      this.mail = inputuserInfo.mail
    }
  },
  created() {

  },
  methods: {
    inputChange(e) {
      e.target.value = e.target.value.replace(/[^\d]/g, "");
    },
    change() {
      this.name1 = $("#user_name")[0].value.replace(/(^\s*)|(\s*$)/g, "")
      this.kana1 = $("#user_name_kana")[0].value.replace(/(^\s*)|(\s*$)/g, "")
      sessionStorage.setItem('inputuserinfo', JSON.stringify({
        name1: this.name1,
        kana1: this.kana1,
        birthday_y: this.birthday_y,
        birthday_m: this.birthday_m,
        birthday_d: this.birthday_d,
        tel: this.tel,
        mail: this.mail,
      }))
      if (this.completeInfo.configCode) {
        window.location.assign(`/Reservations?k=${this.completeInfo.configCode}`)
        // this.$router.push({
        //   path: "/Reservations",
        //   query: { k: this.completeInfo.configCode },
        // });
      } else {
        window.location.assign(`/Reservations`)
        // this.$router.push({
        //   path: "/Reservations",
        // });
      }
    },

    confirm() {
      this.name1 = $("#user_name")[0].value.replace(/(^\s*)|(\s*$)/g, "")
      this.kana1 = $("#user_name_kana")[0].value.replace(/(^\s*)|(\s*$)/g, "")
      this.verfiyName();
      this.verfiykana();
      this.verfiyBirth();
      this.getBirthday_d(this.birthday_d);
      this.verfiyPhone();
      this.verfiyMail();
      this.err = JSON.parse(JSON.stringify(this.err));
      let err = this.err.filter((item) => item == true);
      console.log(err, "err");
      if (err.length != 0) {
        this.errShow = true;
      } else {
        this.errShow = false;
      }
      if (!this.errShow) {
        this.$store.commit("updateUserInfo", this.tel.length == 10 ? {
          name1: this.name1,
          kana1: this.kana1,
          birthday_y: this.birthday_y,
          birthday_m: this.birthday_m,
          birthday_d: this.birthday_d,
          homeTel: this.tel,
          mail: this.mail,
        } : {
          name1: this.name1,
          kana1: this.kana1,
          birthday_y: this.birthday_y,
          birthday_m: this.birthday_m,
          birthday_d: this.birthday_d,
          tel: this.tel,
          mail: this.mail,
        });
        sessionStorage.setItem('inputuserinfo', JSON.stringify({
          name1: this.name1,
          kana1: this.kana1,
          birthday_y: this.birthday_y,
          birthday_m: this.birthday_m,
          birthday_d: this.birthday_d,
          tel: this.tel,
          mail: this.mail,
        }))
        this.$router.push("/Reservations/confirm");
      } else {
        window.scroll(0, 0);
      }
    },

    verfiyName() {
      if (this.name1) {
        this.err[0] = false;
      } else {
        this.err[0] = true;
      }
    },
    verfiykana() {
      var kana1 = this.kana1;
      kana1 = kana1.replace("ー", "");
      kana1 = kana1.replace("・", "");
      if (kana1) {
        this.err[1] = false;
        if (!this.kanaReg.test(kana1)) {
          this.err[7] = true;
        } else {
          this.err[7] = false;
        }
      } else {
        this.err[7] = false;
        this.err[1] = true;
      }
    },
    verfiyBirth() {
      if (this.birthday_y != "--") {
        this.err[2] = false;
      } else {
        this.err[2] = true;
      }
      if (this.birthday_m != "--") {
        this.err[3] = false;
      } else {
        this.err[3] = true;
      }
      if (this.birthday_d != "--") {
        this.err[4] = false;
      } else {
        this.err[4] = true;
      }
    },
    verfiyPhone() {
      if (this.tel) {
        this.err[5] = false;
        if (this.telReg.test(this.tel)) {
          this.err[9] = false;
        } else {
          this.err[9] = true;
        }
      } else {
        this.err[5] = true;
        this.err[9] = false;
      }
    },
    verfiyMail() {
      if (this.mail) {
        this.err[6] = false;
        if (this.mailReg.test(this.mail)) {
          this.err[8] = false;
        } else {
          this.err[8] = true;
        }
      } else {
        this.err[8] = false;
        this.err[6] = true;
      }
    },

    getBirthday_y(info) {
      this.birthday_y = 1949 + info;
    },
    getBirthday_m(info) {
      this.birthday_m = info;
    },
    getBirthday_d(info) {
      this.birthday_d = info;
      let date, month, day;
      this.birthday_m < 10
        ? (month = "0" + this.birthday_m)
        : (month = this.birthday_m);
      this.birthday_d < 10
        ? (day = "0" + this.birthday_d)
        : (day = this.birthday_d);
      date = this.birthday_y + "-" + month + "-" + day;
      if (!this.dateReg.test(date)) {
        this.birthdayErr = true;
      } else {
        this.birthdayErr = false;
      }
    },
  },
};
</script>
